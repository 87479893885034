import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Interno from './interno';
import Manutencao from './erro/manutencao';
import Erro from './erro';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import alerta from '../signals/alerta';
import carregando from '../signals/carregando';
import { useSignals } from '@preact/signals-react/runtime';

const Home = () => {
    useSignals();

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Switch>
                <Route path="/erro" exact component={Erro} />
                {
                    global.gConfig.manutencao
                        ?
                        <Route path="/" component={Manutencao} />
                        :
                        <Route path="/" component={Interno} />
                }
            </Switch>
            <SttAlerta {...alerta.value} />
            <SttLoading {...carregando.value} />
        </BrowserRouter>
    );
};

export default Home;
