import { batch, signal } from "@preact/signals-react";

export const listaAtendimentoAberto = signal(true);
export const usuariosAtendimento = signal([]);
export const emAtendimento = signal(false);
export const atendimentoEncerrado = signal(false);
export const idSalaAtendimento = signal();
export const idAtendimento = signal();
export const usuarioEmAtendimento = signal();
export const emChamadaVideo = signal(false);
export const historicoAberto = signal(false);
export const quantidadeMembrosSala = signal(0);
export const solicitouAtendimento = signal(false);
export const atendenteOnline = signal(false);

export const resetSemAtendimento = () => {
    batch(() => {
        emAtendimento.value = false;
        atendimentoEncerrado.value = false;
        idSalaAtendimento.value = null;
        idAtendimento.value = null;
        usuarioEmAtendimento.value = null;
        emChamadaVideo.value = false;
        historicoAberto.value = false;
        quantidadeMembrosSala.value = 0;
        usuariosAtendimento.value = [...usuariosAtendimento.value];
    });
}

export const resetAtendidoNovoAtendimento = () => {
    batch(() => {
        emAtendimento.value = false;
        atendimentoEncerrado.value = false;
        idSalaAtendimento.value = null;
        idAtendimento.value = null;
        emChamadaVideo.value = false;
        historicoAberto.value = false;
        quantidadeMembrosSala.value = 0;
        solicitouAtendimento.value = true;
        atendenteOnline.value = false;
    });
}