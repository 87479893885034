import React, { useContext } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import {
    SttHeader,
    SttSplashScreen,
    MateriaisSuporte,
    SttTranslateHook,
    SttMainNavigationBar,
    SttNotificationScreen,
} from '@stt-componentes/core';
import { signal } from '@preact/signals-react';
import { getHeaders } from '../request';
import usuario from '../signals/usuario';
import { useSignals, useSignalEffect, useSignal } from '@preact/signals-react/runtime';

const openMS = signal(false);
const openSS = signal(false);

const Menu = () => {
    const history = useHistory();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    const exibirNotificacao = useSignal(false);

    useSignalEffect(() => {
        if (!usuario.value.perfisRBAC || usuario.value.perfisRBAC.length === 0) {
            history.push(`/nao-autorizado`);
            return;
        }

        exibirNotificacao.value = true;
    });

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
                callbackNavigationBar={(event, newTab) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
        </div>
    );
};

export default withRouter(Menu);