import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttModal,
    SttButton,
    SttGrid,
    SttLoading,
    SttTranslateHook,
    SttAutocomplete
} from '@stt-componentes/core';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { usuarioEmAtendimento } from '../signals/atendimentos';
import socket from '../signals/socket';
import { EVENTOS_SOCKET } from '../common/Constants';
import usuario from '../signals/usuario';
import alerta from '../signals/alerta';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    formItem: {
        width: '100%'
    }
}));

const ModalEncaminhamento = ({ encaminharAtendimento }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    useSignals();

    const funcionarioSelecionado = useSignal(null);
    const funcionariosDisponiveis = useSignal([]);
    const encaminhando = useSignal(false);

    useEffect(() => {
        socket.value.emit(EVENTOS_SOCKET.LISTA_ATENDENTES);
        socket.value.on(EVENTOS_SOCKET.LISTA_ATENDENTES, (lista) => {
            lista = JSON.parse(lista);

            let itens = Object.values(lista);
            console.log(lista)
            if (itens.length === 1) {
                // Apenas o próprio atendente está conectado.
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    type: 'alert',
                    title: strings.atencao,
                    message: strings.semAtendenteEncaminhar,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                encaminharAtendimento.value = false;
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }
                        }
                    ],
                    onClose: () => {
                        encaminharAtendimento.value = false;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                }
                return;
            }
            itens = itens.filter(item => item.idFuncionario !== usuario.value.idFuncionario);
            itens = itens.sort((a, b) => {
                if (a.nome < b.nome) return -1;
                if (a.nome > b.nome) return 1;
                return 0;
            });
            funcionariosDisponiveis.value = itens;
        });

        return (() => {
            socket.value.off(EVENTOS_SOCKET.LISTA_ATENDENTES);
        })
    }, [])

    const handleClose = () => {
        encaminharAtendimento.value = false;
    }

    const changeHandler = (event, item) => {
        funcionarioSelecionado.value = item || null;
    };

    const encaminhar = () => {
        socket.value.emit(EVENTOS_SOCKET.ENCAMINHAR_ATENDIMENTO, JSON.stringify({
            idFuncionarioSelecionado: funcionarioSelecionado.value.idFuncionario,
            idFuncionarioAtendimento: usuarioEmAtendimento.value.idFuncionario
        }));
        encaminharAtendimento.value = false;
    }

    return (
        <>
            <SttModal
                title={strings.encaminharAtendimento}
                open={true}
                outModalClose={handleClose}
                iconClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div className={classes.form}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12} md={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        name: 'funcionario',
                                        label: strings.funcionario,
                                        required: true
                                    }}
                                    className={classes.formItem}
                                    getOptionLabel={option => option?.nome || ''}
                                    options={funcionariosDisponiveis.value}
                                    value={funcionarioSelecionado.value}
                                    onChange={changeHandler}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton disabled={!funcionarioSelecionado.value} variant="contained" color="primary" onClick={encaminhar}>
                            {strings.encaminhar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={handleClose}>
                            {strings.fechar}
                        </SttButton>
                    </div>
                }
            />
            <SttLoading
                open={encaminhando.value}
                text={strings.encaminhando}
            />
        </>
    );
};

export default ModalEncaminhamento;