import alerta from "../signals/alerta";
import {
    atendimentoEncerrado,
    emAtendimento,
    historicoAberto,
    idSalaAtendimento,
    resetSemAtendimento,
    usuarioEmAtendimento
} from "../signals/atendimentos";
import socket from "../signals/socket";
import usuario from "../signals/usuario";
import { EVENTOS_SOCKET, SITUACAO_ATENDIMENTO } from "./Constants";
import { batch } from "@preact/signals-react";

export const mudarNomeTab = (nome) => {
    document.title = nome;
}

export const pedirPermissaoNotificacao = (exibirNotificacao = () => { }) => {
    if (!("Notification" in window)) return;

    if (Notification.permission === 'granted') {
        // Permissão já concedida
        exibirNotificacao();
    } else if (Notification.permission !== 'denied') {
        // Pedir permissão ao usuário
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                exibirNotificacao();
            }
        });
    }
}

export const resetTab = () => {
    mudarNomeTab(global.gConfig.titulo_aplicacao);
}

const abrirAtendimento = (item) => {
    const dadosUsuario = {
        nome: usuario.value.nome,
        idFuncionario: usuario.value.idFuncionario,
        id: usuario.value.idFuncionario,
        cpf: usuario.value.cpf,
        uf: usuario.value.estado,
        municipio: usuario.value.cidade,
    };

    const dados = {
        idFuncionarioAtendimento: item.idFuncionario,
        funcionarioAtendente: dadosUsuario
    };

    historicoAberto.value = false;
    if (item.situacaoAtendimento <= SITUACAO_ATENDIMENTO.SITUACAO.ENCAMINHADO) {
        socket.value.emit(EVENTOS_SOCKET.INICIAR_ATENDIMENTO, JSON.stringify(dados));
        batch(() => {
            emAtendimento.value = true;
            usuarioEmAtendimento.value = item;
            atendimentoEncerrado.value = false;
        })
        return;
    }
    batch(() => {
        emAtendimento.value = true;
        usuarioEmAtendimento.value = item;
        atendimentoEncerrado.value = true;
    });
}

export const onClickCardUsuario = (item, strings) => {
    // Clicou no mesmo usuário que já estava atendendo e retornou para um novo atendimento
    if (emAtendimento.value && item.idFuncionario === usuarioEmAtendimento.value.idFuncionario && item.situacaoAtendimento <= SITUACAO_ATENDIMENTO.SITUACAO.SOLICITOU_ATENDIMENTO) {
        resetSemAtendimento();
        abrirAtendimento();
        return;
    }

    // Clicou em outro usuário enquanto já está atendendo
    if (emAtendimento.value && item.idFuncionario !== usuarioEmAtendimento.value.idFuncionario && !atendimentoEncerrado.value) {
        alerta.value = {
            ...alerta.value,
            open: true,
            type: 'alert',
            title: strings.atencao,
            message: strings.notificacaoNovoAtendimento,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        socket.value.emit(EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO, { idSala: idSalaAtendimento.value });
                        resetSemAtendimento();
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        abrirAtendimento(item);
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            ],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                };
            }
        }
        return;
    }

    resetSemAtendimento();
    abrirAtendimento(item);
}