import React, { useContext } from "react";
import {
    SttBox,
    SttHeading,
    SttHidden,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import {
    atendenteOnline,
    atendimentoEncerrado,
    emAtendimento,
    emChamadaVideo,
    historicoAberto,
    idSalaAtendimento,
    resetAtendidoNovoAtendimento,
    resetSemAtendimento,
    solicitouAtendimento,
    usuarioEmAtendimento
} from "../signals/atendimentos";
import { temPermissaoRBAC } from "../security/rbac";
import usuario from "../signals/usuario";
import { CONSTANTES, EVENTOS_SOCKET } from "../common/Constants";
import socket from "../signals/socket";
import ChatFormContainer from './chatFormContainer';
import MensagensContainer from './mensagensCointainer';
import SolicitarAtendimentoIcon from "./icones/solicitarAtendimentoIcon";
import ModalEncaminhamento from "./modalEncaminhamento";
import {
    CameraDeVideoDesligadaIcon,
    CameraDeVideoLigadaIcon,
    EncaminharAtendimentoIcon,
    HistoricoIcon,
    XisIcon,
    DesligarIcon
} from '@stt-componentes/icons'

const useStyles = makeStyles(theme => ({
    heading: {
        marginLeft: theme.spacing(2)
    },
    headerBox: {
        height: '63px',
        padding: "0px 15px",
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    iconButton: {
        marginLeft: theme.spacing(1.5),
    }
}));

const AtendimentoContainer = ({ atendimentoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const encaminharAtendimento = useSignal(false);

    return (
        <>
            {
                usuarioEmAtendimento.value &&
                // Container com o header do painel
                <SttBox className={classes.headerBox}>
                    {/* Container com o título/subtítulo do cabeçalho */}
                    <SttBox
                        display="flex"
                        flexDirection="column"
                        pl="4px"
                        alignItems="flex-start"
                    >
                        {
                            temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO)
                                ?
                                <>
                                    <SttBox display="flex" justifyContent="space-between" width="100%">
                                        <SttText variant="body1" size="small" color="textPrimary">
                                            <strong>{usuarioEmAtendimento.value.nome}</strong>
                                        </SttText>
                                    </SttBox>
                                    <SttText variant="caption" size="small" color="textPrimary">
                                        {usuarioEmAtendimento.value.municipio.nome} / {usuarioEmAtendimento.value.uf.sigla}
                                        <SttHidden xsDown>
                                            &nbsp;- CBO: {usuarioEmAtendimento.value.cbo.descricao_cbo_ocupacao}
                                        </SttHidden>
                                    </SttText>
                                </>
                                :
                                <SttBox display="flex" justifyContent="space-between" width="100%">
                                    <SttHeading variant="h2" color="primary">{strings.suporte}</SttHeading>
                                </SttBox>
                        }
                    </SttBox>
                    {/* Container com os botões de ação */}
                    <SttBox display="flex" alignItems="flex-end">
                        <SttHidden smUp>
                            {
                                emChamadaVideo.value && atendimentoAberto?.value &&
                                <Tooltip title={strings.abrirJanelaVideo}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={() => {
                                            atendimentoAberto.value = false;
                                        }}>
                                        <CameraDeVideoLigadaIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SttHidden>
                        {
                            !temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO) && !solicitouAtendimento.value && atendenteOnline.value && (!emAtendimento.value || (emAtendimento.value && atendimentoEncerrado.value)) &&
                            <Tooltip title={strings.solicitarAtendimento}>
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                    onClick={() => {
                                        if (emAtendimento.value) {
                                            resetAtendidoNovoAtendimento();
                                        }
                                        socket.value.emit(EVENTOS_SOCKET.SOLICITAR_ATENDIMENTO);
                                        solicitouAtendimento.value = true;
                                    }}>
                                    <SolicitarAtendimentoIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={strings.historicoAtendimento}>
                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                style={{
                                    backgroundColor: historicoAberto.value ? '#ECECEC' : undefined
                                }}
                                onClick={() => {
                                    historicoAberto.value = !historicoAberto.value;
                                }}>
                                <HistoricoIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            emAtendimento.value && !atendimentoEncerrado.value &&
                            <>
                                {
                                    temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO) &&
                                    <>
                                        {
                                            !emChamadaVideo.value ?
                                                <Tooltip title={strings.chamadaVideo}>
                                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                        onClick={() => {
                                                            socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                                idSala: idSalaAtendimento.value,
                                                                videoAtivo: true
                                                            }));
                                                        }}>
                                                        <CameraDeVideoLigadaIcon />
                                                    </IconButton>
                                                </Tooltip> :
                                                <Tooltip title={strings.fecharChamadaVideo}>
                                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                        onClick={() => {
                                                            socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                                idSala: idSalaAtendimento.value,
                                                                videoAtivo: false
                                                            }));
                                                        }}>
                                                        <CameraDeVideoDesligadaIcon />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                        <Tooltip title={strings.encaminharAtendimento}>
                                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                onClick={() => {
                                                    encaminharAtendimento.value = true;
                                                }}>
                                                <EncaminharAtendimentoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            </>
                        }
                        {
                            !(atendimentoEncerrado.value || !emAtendimento.value) && temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO) &&
                            <Tooltip title={strings.finalizarAtendimento}>
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                    onClick={() => {
                                        socket.value.emit(EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO, { idSala: idSalaAtendimento.value });
                                    }}>
                                    <DesligarIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO) &&
                            <Tooltip title={strings.fecharAtendimento}>
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                    onClick={() => {
                                        socket.value.emit(EVENTOS_SOCKET.SAIR_ATENDIMENTO, { idSala: idSalaAtendimento.value });
                                        resetSemAtendimento();
                                    }}>
                                    <XisIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </SttBox>

                </SttBox >
            }
            {/* Container de mensagens enviadas */}
            <SttBox flex={1} minHeight={0} position="relative">
                <SttBox
                    height="100%"
                    width="100%"
                    alt="background"
                />
                <MensagensContainer historicoAberto={historicoAberto} />
            </SttBox>
            {
                emAtendimento.value &&
                // Container com o input de texto
                <ChatFormContainer />
            }
            {
                encaminharAtendimento.value &&
                <ModalEncaminhamento encaminharAtendimento={encaminharAtendimento} />
            }
        </>
    );
}

export default AtendimentoContainer;