
import React, { useContext } from "react";
import { SttTranslateHook } from '@stt-componentes/core';
import socket from "../../signals/socket";
import { CONSTANTES, EVENTOS_SOCKET, SITUACAO_ATENDIMENTO } from "../../common/Constants";
import { listaAtendimentoAberto, usuarioEmAtendimento, usuariosAtendimento } from "../../signals/atendimentos";
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { pegarEstadosVinculoPerfil, temPerfilRBAC } from "../../security/rbac";
import usuario from "../../signals/usuario";
import { mudarNomeTab, onClickCardUsuario, pedirPermissaoNotificacao, resetTab } from "../../common/Functions";
import { SttListaAtendimento } from '@stt-componentes/atendimento';
import { getHeaders } from "../../request";

const PainelEsquerdo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const exibirNotificacao = () => {
        const options = {
            body: strings.notificacaoSolicitacaoAtendimento,
            icon: global.gConfig.url_icone_notificacao,
            vibrate: [200, 100, 200],
            tag: 'notificacao-helpdesk',
            renotify: true
        };

        const notification = new Notification(global.gConfig.titulo_aplicacao, options);
        mudarNomeTab(strings.notificacaoTabSolicitacaoAtendimento);

        notification.onclick = () => {
            window?.focus();
            resetTab();
        };

        window.addEventListener('focus', resetTab);
    }

    useSignalEffect(() => {
        usuariosAtendimento.value.forEach(item => {
            item.onClick = () => onClickCardUsuario(item, strings);

            item.background = () => '';
            if (usuarioEmAtendimento.value?.idFuncionario === item.idFuncionario) {
                item.background = () => '#EFEFEF';
            } else if (item.situacaoAtendimento === SITUACAO_ATENDIMENTO.SITUACAO.ENCAMINHADO && item.idFuncionarioEncaminhado === usuario.value.idFuncionario) {
                item.background = () => '#FCF4E4';
            }
        });
        usuariosAtendimento.value = usuariosAtendimento.value;
    })

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.LISTA_PARA_ATENDIMENTO, (dados) => {
                dados = JSON.parse(dados);

                // Filtrando a listagem para atendentes com vínculo estadual.
                if (temPerfilRBAC(usuario, CONSTANTES.PERFIL.ATENDENTE_HELPDESK_ESTADUAL)) {
                    const ufs = pegarEstadosVinculoPerfil(usuario, CONSTANTES.PERFIL.ATENDENTE_HELPDESK_ESTADUAL);
                    dados = dados.filter(d => ufs.includes(d.uf.id));
                }
                usuariosAtendimento.value = dados;
            });

            socket.value.on(EVENTOS_SOCKET.NOTIFICACAO_SOLICITACAO_ATENDIMENTO, () => {
                pedirPermissaoNotificacao(exibirNotificacao);
            });
        }
    });

    return (
        <SttListaAtendimento
            listaAtendimentoAberto={listaAtendimentoAberto}
            lista={usuariosAtendimento}
            strings={strings}
            headers={getHeaders()}
        />
    );
}

export default PainelEsquerdo;