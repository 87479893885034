import React, { useContext, useEffect } from "react";
import {
    SttBox,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { makeStyles } from "@material-ui/core";
import { atendenteOnline, solicitouAtendimento } from "..//signals/atendimentos";
import usuario from "..//signals/usuario";
import socket from "../signals/socket";
import { EVENTOS_SOCKET } from "../common/Constants";
import AtendenteOnlineIcon from '../img/atendente-online.png';
import AtendenteOfflineIcon from '../img/atendente-offline.png';

const useStyles = makeStyles(theme => ({
    divNotification: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: theme.spacing(0.5)
        },
    }
}));

const PainelEsperaContainer = ({ historicoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.ATENDENTE_ONLINE, (dados) => {
                // Recebimento de uma nova mensagem
                const mensagemRecebida = JSON.parse(dados);
                atendenteOnline.value = mensagemRecebida.atendenteOnline;
            });
        }
    })

    useEffect(() => {
        return () => {
            if (socket.value) {
                socket.value.removeAllListeners(EVENTOS_SOCKET.ATENDENTE_ONLINE);
            }
        }
    }, []);

    return (
        <SttBox className={classes.divNotification} >
            {
                atendenteOnline.value
                    ?
                    <img src={AtendenteOnlineIcon} style={{ maxWidth: '100px' }} alt="Atendente online" />
                    :
                    <img src={AtendenteOfflineIcon} style={{ maxWidth: '100px' }} alt="Atendente offline" />
            }
            <SttText variant="caption" color="textPrimary" style={{ marginLeft: '10px', lineBreak: 'auto', paddingRigth: '25px' }}>
                {strings.textoAguarde(usuario.value.nome)}
                <br />
                {
                    atendenteOnline.value
                        ?
                        <>
                            {
                                solicitouAtendimento.value
                                    ?
                                    strings.textoAguardeAtendimento
                                    :
                                    <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: strings.textoCliqueSolicitarAtendimento }}
                                        onClick={() => {
                                            socket.value.emit(EVENTOS_SOCKET.SOLICITAR_ATENDIMENTO);
                                            solicitouAtendimento.value = true;
                                        }}
                                    />
                            }
                        </>
                        :
                        strings.textoAguardeSemAtendentes
                }
                {/* <br /> */}
                <div style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: strings.textoCliqueAbrirHistorico }}
                    onClick={() => {
                        historicoAberto.value = true;
                    }}
                />
            </SttText>
        </SttBox>
    );
}

export default PainelEsperaContainer;
